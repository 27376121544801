import isMobile from 'ismobilejs'

let menu
let nav
let opened

function onMenuClick() {
  if (!opened) {
    menu.classList.add('active')
    nav.classList.add('opened')
  } else {
    menu.classList.remove('active')
    nav.classList.remove('opened')
  }

  opened = !opened
}

function createMenu() {
  opened = false
  menu = document.querySelector('#burgerMenu')
  nav = document.querySelector('.nav')
  menu.addEventListener('click', onMenuClick)
}

exports.init = () => {
  if (isMobile.any) {
    createMenu()
  }
}
