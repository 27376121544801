import Masonry from 'masonry-layout'
import isMobile from 'ismobilejs'

exports.init = () => {
  if (!isMobile.any) {
    var msnry = new Masonry( '.news-container', {
      columnWidth: '.grid-item',
      itemSelector: '.grid-item'
    });
  }
}
