let container
let slides
let prevButton
let nextButton
let currentSlide
let numSlides

function prepareSlides(elements, width) {
  for (let i = 0; i < elements.length; i++) {
    const slide = elements[i]
    const x = i * width
    slide.style.left = x + 'px'
  }
}

function goNext() {
  currentSlide++
  if (currentSlide > numSlides) {
    currentSlide = numSlides
  }
  setSlide(currentSlide)
}

function goPrev() {
  currentSlide--
  if (currentSlide < 0) {
    currentSlide = 0
  }
  setSlide(currentSlide)
}

function setSlide(num) {
  const px = `-${num * 100}%`
  container.style.left = px
  if (num === 0) {
    prevButton.style.display = 'none'
    nextButton.style.display = 'block'
  } else if (num === numSlides) {
    prevButton.style.display = 'block'
    nextButton.style.display = 'none'
  } else {
    prevButton.style.display = 'block'
    nextButton.style.display = 'block'
  }
}

function setup() {
  container = document.querySelector('.slides')
  if (!container) {
    return false
  }
  slides = container.querySelectorAll('li')
  numSlides = slides.length - 1
  prepareSlides(slides, container.clientWidth)
  nextButton = document.querySelector('button#nextButton')
  prevButton = document.querySelector('button#prevButton')
  return true
}

function onResize() {
  setup()
}

function init() {
  currentSlide = 0
  if (setup()) {
    nextButton.addEventListener('click', goNext)
    prevButton.addEventListener('click', goPrev)
    window.addEventListener('resize', onResize)
    window.addEventListener('orientationchange', onResize)
  }
}

exports.init = init
