import domReady from 'domready'
import HomeView from './home'
import Slideshow from './slideshow'
import MobileMenu from './mobilemenu'
import Companies from './companies'

// make global object
window.domReady = domReady

// our app namespace
const App = () => {
  return {
    initApp: () => {
      MobileMenu.init()
    },
    initHome: () => {
      HomeView.init()
    },
    initSlideshow: () => {
      Slideshow.init()
    },
    initCompanies: () => {
      Companies.init()
    }
  }
}

window.App = App()
