import isMobile from 'ismobilejs'

let nav
let selectorEl
let isVisible

function toggleMenu(val) {
  if (val) nav.classList.add('visible')
  else nav.classList.remove('visible')
}

function onScroll() {
  const offset = selectorEl.getBoundingClientRect()

  if (Math.abs(offset.top) > offset.height && !isVisible) {
    isVisible = true
    toggleMenu(true)
  } else if (Math.abs(offset.top) < offset.height && isVisible) {
    isVisible = false
    toggleMenu(false)
  }
}

exports.init = () => {
  if (!isMobile.any) {
    isVisible = false
    nav = document.querySelector('nav.company-nav')
    selectorEl = document.querySelector('section.company-selector')
    document.addEventListener('scroll', onScroll)
  }
}
